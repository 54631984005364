<template>
    <Setup-Step
        @yes="f => addNew()"
        @no="f => { f(); }"
        :loadingMsg="loadingMsg"
        :settings="settings"
        :stage.sync="stage"
        step="courier-payment-terms"
        title="Payment Terms">
        <template v-slot:body>
            <v-tabs-items v-model="stage" touchless>
                <v-tab-item>
                    <BT-List-Endless
                        :canSearch="false"
                        :loadingMsg.sync="loadingMsg"
                        maxHeight="60vh"
                        navigation="courier-payment-terms"
                        :refreshToggle="refreshList">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.paymentTermsName }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn small text @click="addNew(item)">change</v-btn>
                            </v-list-item-action>
                        </template>
                    </BT-List-Endless>
                    <p class="my-4">Would you like to add payment terms?</p>
                </v-tab-item>

                <v-tab-item>
                    <BT-Field-String
                        label="Payment Terms Name"
                        v-model="newItem.paymentTermsName"
                        isEditing />

                    <BT-Btn 
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>

                <v-tab-item>
                    <p>Are these terms to pay end of month?</p>

                    <Setup-Info>
                        End of Month means that the customer must issue payment within a certain number of days following the end of the month.
                    </Setup-Info>

                    <BT-Btn
                        buttonClass="primary mx-1"
                        inline
                        label="No"
                        @click="notEndOfMonth" />

                    <BT-Btn
                        buttonClass="primary mx-1"
                        inline
                        label="Yes"
                        @click="endOfMonth" />
                    
                </v-tab-item>

                <v-tab-item>
                    <p v-if="newItem.endOfMonth">How many days into the following month should payment be due?</p>
                    <p v-else>How many days after day of issue should payment be due?</p>

                    <BT-Field-Number
                        v-model.number="newItem.daysToPay"
                        label="Days To Pay"
                        isEditing />
                    
                    <BT-Btn
                        buttonClass="primary mx-1"
                        inline
                        label="Done"
                        @click="save" />
                </v-tab-item>
            </v-tabs-items>
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Courier-Payment-Terms-Setup',
    components: {
        SetupInfo: () => import('~home/setup/Setup-Info.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            loadingMsg: null,
            newItem: {},
            refreshList: false,
            stage: 0
        }
    },
    props: {
        settings: { //{ currentIndex: 0, stepperPlan: [], currentStep: 0 }
            type: Object,
            default: null
        },
    },
    methods: {
        addNew(j) {
            this.newItem = j || { };
            this.stage += 1;
        },
        endOfMonth() {
            this.newItem.endOfMonth = true;
            this.stage += 1;
        },
        notEndOfMonth() {
            this.newItem.endOfMonth = false;
            this.stage += 1;
        },
        async save() {
            try {
                this.loadingMsg = 'Saving';
                var res = null;

                if (this.newItem.id == null) {
                    res = await this.$BlitzIt.store.post('courier-payment-terms', this.newItem);
                }
                else {
                    res = await this.$BlitzIt.store.patch('courier-payment-terms', this.newItem);
                }
                
                this.newItem.id = res.id;
                this.newItem.rowVersion = res.rowVersion;

                this.stage = 0;
                this.refreshList = !this.refreshList;
            }
            finally {
                this.loadingMsg = null;
            }
            
        },
    }
}
</script>